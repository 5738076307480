// import http from "@/utils/request";

import dmshttp from "@/utils/dmsrequest";
export function getProductList(data) {
    return dmshttp({
        url: 'getSalesOrderDetailList',
        data
    })
}

// 导出数据
export function exportData(data) {
    return dmshttp({
        url: 'exportSalesOrderDetailList' + '&type=1',
        data,
        responseType: 'blob'
    })
}
export function getImg(data) {
    return dmshttp({
        url: 'toOmsView',
        data
    })
}

//打印
export function salesOrderPrint(data) {
    return dmshttp({
        url: 'salesOrderPrint',
        data
    })
}

// 发货条码导出
export function exportSalesOrder(data) {
    return dmshttp({
        url: 'exportSalesOrderDetailSncenter'+ '&type=1',
        data
    })
}