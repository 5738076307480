import {
  getProductList,
  exportData,
  getImg,
  salesOrderPrint,
  exportSalesOrder
} from './api'
import navList from '../navList'
import Util from "@/utils/utils";
import {
  backCheckproxysign,
  dmsSendLadingContract,
  sumbitAttorney
} from "@/views/order/hisenseProcurementProgress/api";
import { publicPath } from "@/common/constant";
const BASE_API = publicPath; // 地址
export default {
  data() {
    return {
      printTitleChangeFlag:false,
      printTitle:'',
      dataList: [],
      navList: [],
      isChange: false,
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },

      {
        path: "/dmspurchaseProgress",
        name: "detail",
        title: "销售订单汇总"
      }
      ],
      total: 0,
      showTotalColumns: ['出库数量', '签收数量', '下单数量', '订单金额', '审核数量', '发货数量', '发货金额', '甩货数量', ],
      tcolumns: [
        {
          label: "客户名称",
          prop: "customerName",
          ellipsis: true,
          width: 200,
        },
        {
          label: "订单创建日期",
          prop: "createDate",
          ellipsis: true,
          sortable: 'custom',
          sortName: 'so.document_date',
          width: 200,
        },
        {
          label: "物料组",
          prop: "materialGroupName",
          ellipsis: true,
          width: 200,
        },
        {
          label: "型号",
          prop: "model",
          ellipsis: true,
          width: 200,
        },
        {
          label: "颜色",
          prop: "colour",
          ellipsis: true,
          width: 200,
        }, 
        {
          label: "库存状态",
          prop: "invStatusName",
          ellipsis: true,
          width: 200,
        },
        {
        label: "销售单号",
        prop: "documentNum",
        ellipsis: true,
        slot: "documentNum",
        sorter: true,
        sortName: 'so.document_num',
        sortable: 'custom',
        width: 200,
      },
      {
        label: "采购单号",
        prop: "purchaseNum",
        ellipsis: true,
        width: 200,
      },
      {
        label: "发货单号",
        prop: "stvDocumentNum",
        ellipsis: true,
        slot: "stvDocumentNum",
        width: 200,
      },
      {
        label: "发货仓库",
        prop: "gicOutWarehouseName",
        ellipsis: true,
        width: 200,
      },
      {
        label: "配送方式",
        prop: "deliveryTypeName",
        ellipsis: true,
        width: 200,
      },
      {
        label: "自提委托书",
        prop: "attorneyLetter",
        ellipsis: true,
        slot: "attorneyLetter",
        width: 500,
        value: true
      },
      {
        label: "订单状态",
        prop: "statusName",
        ellipsis: true,
        sorter: true,
        sortName: 'so.status',
        sortable: 'custom',
        width: 250,
      },
      {
        label: "发货单状态",
        prop: "stvStatusName",
        ellipsis: true,
        width: 250,
      },
      {
        label: "物流状态",
        prop: "omsStatusName",
        ellipsis: true,
        width: 200,
      },
      {
        label: "发货单创建时间",
        prop: "stvCreateDate",
        ellipsis: true,
        width: 300,
      },
      {
        label: "下单数量",
        prop: "totalNum",
        align: 'right',
        ellipsis: true,
        width: 200,
      },
      {
        label: "审核数量",
        prop: "backnowledgedQty",
        ellipsis: true,
        align: 'right',
        width: 200,
      },
      {
        label: "发货数量",
        prop: "bDemandQty",
        ellipsis: true,
        align: 'right',
        width: 200,
      },
      {
        label: "甩货数量",
        prop: "shQty",
        align: 'right',
        ellipsis: true,
        width: 200,
      },
      {
        label: "签收数量",
        prop: "signQty",
        align: 'right',
        ellipsis: true,
        width: 200,
      },
      {
        label: "拒收数量",
        prop: "rejectQty",
        align: 'right',
        ellipsis: true,
        width: 200,
      },
      {
        label: "短少数量",
        prop: "lostQty",
        align: 'right',
        ellipsis: true,
        width: 200,
      },
      {
        label: "签收时间",
        prop: "signDate",
        ellipsis: true,
        width: 200,
      },
      {
        label: "订单金额",
        prop: "totalAmount",
        align: 'right',
        ellipsis: true,
        width: 200,
      },
      {
        label: "发货金额",
        prop: "outAmount",
        align: 'right',
        ellipsis: true,
        width: 200,
      },

      {
        label: "参与活动单号",
        prop: "userActivityCode",
        ellipsis: true,
        width: 260,
      },
      {
        label: "活动类型",
        prop: "discountTypeName",
        ellipsis: true,
        width: 160,
      },
      {
        label: "组合编码",
        prop: "packageCode",
        ellipsis: true,
        width: 160,
      },
      {
        label: "产品分组编码",
        prop: "productGroup",
        ellipsis: true,
        width: 260,
      },
      {
        label: "产品分组说明",
        prop: "productGroupRemark",
        ellipsis: true,
        width: 300,
      },
      {
        label: "比例",
        prop: "packageRateNum",
        ellipsis: true,
        width: 160,
      },
      {
        label: "产品编码",
        prop: "productCode",
        ellipsis: true,
        width: 200
      },
      {
        label: "订单审核时间",
        prop: "lastSumbmitDate",
        ellipsis: true,
        width: 300,
      },
      {
        label: "发货日期",
        prop: "outDate",
        ellipsis: true,
        width: 200,
      },
      {
        label: "发货库存状态",
        prop: "soiInvStatusName",
        ellipsis: true,
        width: 300,
      },
      {
        label: "销售单补差类型",
        prop: "invStatusTypeName",
        ellipsis: true,
        width: 350,
      },
      {
        label: "发货单补差类型",
        prop: "soiInvStatusTypeName",
        ellipsis: true,
        width: 350,
      },
      {
        label: "收货地址",
        prop: "addressDesc",
        ellipsis: true,
        width: 200,
      },
      {
        label: "联系人",
        prop: "customerName",
        ellipsis: true,
        width: 200,
      },
      {
        label: "联系电话",
        prop: "phone",
        ellipsis: true,
        width: 200,
      },
      {
        label: "下单平台",
        prop: "dataSourceValue",
        ellipsis: true,
        width: 200,
      },
      {
        label: "下单用户",
        prop: "dealerCode",
        ellipsis: true,
        width: 200,
      },
      {
        label: "销售单类型",
        prop: "documentTypeName",
        ellipsis: true,
        width: 250,
      },
      {
        label: "销售类型",
        prop: "retailTypeName",
        ellipsis: true,
        width: 200,
      },
      {
        label: "销售门店",
        prop: "storeName",
        ellipsis: true,
        width: 200,
      },
      {
        label: "期望送货日期",
        prop: "edt",
        ellipsis: true,
        width: 250,
      },
      {
        label: "预计送达时间",
        prop: "reserveTime",
        ellipsis: true,
        width: 250,
      },
      {
        label: "客户编码",
        prop: "customerCode",
        ellipsis: true,
        width: 200,
      },
      {
        label: "销售组织名称",
        prop: "orgName",
        ellipsis: true,
        width: 260,
      },
      {
        label: "活动名称",
        prop: "activityName",
        ellipsis: true,
        width: 160,
      },
        {
          label: "退货状态",
          prop: "returnStatusName",
          ellipsis: true,
          width: 160,
        },
      {
        label: "参与活动单号",
        prop: "userActivityCode",
        ellipsis: true,
        width: 260,
      },
      {
        label: "是否专卖店",
        prop: "isSpeclalShop",
        ellipsis: true,
        width: 260,
      },
      {
        label: "是否跨品类",
        prop: "isCrossCategory",
        ellipsis: true,
        width: 160,
        value: true
      },
      {
        label: "订单来源",
        prop: "dataSourceText",
        ellipsis: true,
        width: 160,
        value: true
      },
      // {
      //   label: "出库数量",
      //   prop: "bActualQty",
      //   align: 'right',
      //   ellipsis: true,
      //   width: 200,
      // },
      {
        label: "操作",
        slot: "btnGroup",
        ellipsis: true,
        fixed:'right',
        width: 200,
      },
      ],
      columnsAll:[],// 记录所有列数据 调整列入参用
      tableData: [],
      tabelIsLoading: false,
      //x 最大宽度 y 最大高度
      scroll: {
        x: 2100
      },
      modelDataConfirm: [], // 查询结果
      pagination: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
        sortName: "",
        sortOrder: ""
      },
      terms: {},
      loading: false,
      onluyOneMessage: false,
      imgURL: [],
      orderLoading:false,
      printData:{
        Master:{},
        Details:[],
        countTotal:'',
        amount:'',
      },//打印接口数据
      isDataOk:false,//打印接口数据是否返回
      ztVisible: false,
      sqVisible: false, // 授权弹窗
      scanVisible: false, // 弹出二维码弹窗
      InfoTips: false,
      mesageInfoTips: '',
      selRows:[],
      contractId:'', // 授权书id
      pageLoadFlag:false,
      isLoading:false,
      isExpand: false, // 操作按钮是否展开
      salesOrderLoading: false
    }
  },
  created() {
    this.columnsAll = this.tcolumns;
  },
  mounted() {
    this.navList = navList
  },

  methods: {
    // 点击自提委托书打印
    linkClickZT(obj) {
      if(obj.link) {
        window.open(BASE_API+`/orderLadingContract/downContractById.api?contractId=`+ obj.link,'_blank')
      }
    },
    // 打开字体委托书的弹窗
    openZT(){
      // 如果没勾选 弹出当前提示
      if(this.selRows.length <= 0 ) {
        this.$message.warning('请先选择订单')
        return
      }
      // //TODO 如果勾选数据 不满足后端返回的ladingOperateFlag == Y 就给提示 ladingOperateFlag:是否可以操作签署委托书
      // const OperateFlagRows =  this.selRows.filter(it => it.ladingOperateFlag !== 'Y')
      // const OperateFlagRowsNum =  this.selRows.filter(it => it.ladingOperateFlag !== 'Y').map(it=>it.documentNum).join(',')
      // if(OperateFlagRows.length > 0) {
      //   this.mesageInfoTips = (`${OperateFlagRowsNum}不能进行签署自提委托书的操作，请重新选择`)
      //   this.InfoTips = true
      //   return
      // }
      // //TODO 如果勾选的数据不是自提 提示 BHO202212310131，BHO202212310312的配送方式不是自提，无需签署自提委托书。 it.shareFlagName是否是统仓 嘉赫未提供是否是统仓 需要修改
      const inconformitySelRows =  this.selRows.filter(it => (it.deliveryMode !== '01' && it.deliveryMode !== '10' ))
      const inconformitySelRowsNum =  this.selRows.filter(it => (it.deliveryMode !== '01'&& it.deliveryMode !== '10')).map(it=>it.documentNum).join(',')
      if(inconformitySelRows.length > 0) {
        this.mesageInfoTips = (`${inconformitySelRowsNum}的配送方式不是自提，无需签署自提委托书`)
        this.InfoTips = true
        return
      }
      //  如果物流状态是创建失败提示信息
      const failSelRows =  this.selRows.filter(it => it.omsStatus == 'CREATE_FAIL')
      const failsapSelRowsNum =  this.selRows.filter(it =>it.omsStatus !== 'CREATE_FAIL' ).map(it=>it.documentNum).join(',')
      if(failSelRows.length > 0) {
        this.mesageInfoTips = (`${failsapSelRowsNum}订单未完成提货仓库审核确认，暂不可签署自提委托书`)
        this.InfoTips = true
        return
      }
      // 发货单状态 已失效
      const stvlSelRows =  this.selRows.filter(it => it.stvStatus == 'freeze')
      const stvsapSelRowsNum =  this.selRows.filter(it =>it.stvStatus == 'freeze' ).map(it=>it.documentNum).join(',')
      if(stvlSelRows.length > 0) {
        this.mesageInfoTips = (`${stvsapSelRowsNum}订单未完成提货仓库审核确认，暂不可签署自提委托书`)
        this.InfoTips = true
        return
      }
      //  如果订单状态不是 运输中 提示
      const intransitSelRows =  this.selRows.filter(it => it.status !== 'intransit')
      const intransitSelRowsNum =  this.selRows.filter(it =>it.status !== 'intransit' ).map(it=>it.documentNum).join(',')
      if(intransitSelRows.length > 0) {
        this.mesageInfoTips = (`${intransitSelRowsNum}订单未完成提货仓库审核确认，暂不可签署自提委托书`)
        this.InfoTips = true
        return
      }

      // TODO（4）如果勾选的订单已经签了委托书，弹窗示：“BHO202212310131，BHO202212310312已签署自提委托书，请勿重复签署。 是否签署委托书后端接口提供 嘉赫暂未提供
      const signSelRows =  this.selRows.filter(it => (it.ladingStatus == 1 || it.ladingStatus == 2 ))
      const signSelRowsNum =  this.selRows.filter(it => (it.ladingStatus == 1 || it.ladingStatus == 2) ).map(it=>it.documentNum).join(',')
      if(signSelRows.length > 0) {
        this.mesageInfoTips = (`${signSelRowsNum}已签署自提委托书，请勿重复签署`)
        this.InfoTips = true
        return
      }

      this.ztVisible = true
    },
    // 获取填写的自提委托书的信息
    getztInfo(Info){
      let proArr = this.selRows.map(item => {
        return {
          itemId: item.soItemId,
        };
      })
      let data = {
        itemIds: proArr,
        carName: Info.name,
        carPhone: Info.phone,
        carNumber: Info.carNum,
        carId: Info.idCard,
        orderFrom:'S',
        cisCode:this.$store.state.user.userInfo.customer.cisCode,
      }
      this.pageLoadFlag = true
      // 提交以后接口返回是否授权 返回是的话不展示去授权弹窗  返回否的话就展示授权弹窗 进行下面的操作
      dmsSendLadingContract( data ).then( res => {
        let resData =  JSON.parse(res.data);
        console.log(resData);
        if( resData.code == '1' ){
          this.pageLoadFlag = false
          this.$message.warning( resData.msg )
        } else {
          this.pageLoadFlag = false
          if(resData.data.type == 0) {
            this.$message.success(resData.msg)
            this.pageLoadFlag = false
            this.contractId = resData.data.contractId
            //   授权弹窗
            this.sqVisible = true
            this.ztVisible = false
            this.getData()
          } else {
            this.$message.warning(resData.msg)
            this.contractId =''
            this.ztVisible = false
            this.pageLoadFlag = false
            this.getData()
          }
        }
      })
    },
    // 获取是否签署数据
    async getsqInfo(data){
      //  data 上个页面传过来的数据 1 去签署  0 不签署
      // 如果是点击去签署 就关闭当前页面 打开二维码页面
      if(data == 1){
        this.scanVisible = true
        this.getData()
      }
      this.isLoading = true
      let dataA = {
        pickType: data,
        contractId:this.contractId,
      }
      backCheckproxysign(dataA).then(res=>{
        if(res.code == 1) {
          this.$message.warning(res.data.msg)
          this.getData()
          this.contractId = ''
          this.isLoading = false
        } else {
          this.contractId = ''
          this.isLoading = false
          this.getData()
        }
      })
    },
    // 二维码页面关闭页面
    getscanInfo(){
      this.getData()
    },

    printTitleChangeOk(){
      this.$nextTick(()=>{
        this.printTitleChangeFlag = false;
        this.isDataOk = true;
      })
    },
    // 回单影像
    requestImg(documentNum) {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        documentNum
      }
      getImg(data).then(res => {
        if(res.data){
          var realData = JSON.parse(res.data);
          if (realData.data) {
            this.imgURL = realData.data;
            this.onluyOneMessage = true;
          } else {
            this.$message.warning('暂无回单影像');
          }
        }else{
          this.$message.warning('暂无回单影像');
        }
      })
    },
    exportClick(){
      this.orderLoading = true;
      let paramList = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          model: this.searchIpt,
          colour: "",
          warehouseId: "",
          invStatusId: "",
          isLock: "",
          dealerMaterialGroupFlag: "",
          materialGroupCode: "",
          materialGroupName: "",
          gicWarehouseType: "",
          invStatusType: "",
          invType: "",
          bigQtyMin: 0,
          orgId: "",
          ...this.terms
        },

        page: {
          pageNo: 1,
          pageSize: 300000,
          sortName: this.pagination.sortName,
          sortOrder: this.pagination.sortOrder

        },
      }
      exportData(paramList).then(res => {
        Util.blobToJson(res.data).then(content=>{
          
          if(content&&content.code=='0'){
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err=>{
          Util.downloadFile(res.data,'销售进度汇总列表.xls')
        }).finally(()=>{
          this.orderLoading = false;
        })
      }).catch(err=>{
        this.orderLoading = false;
      });
    },
    downloadFile(blob,name) {
      var reader = new FileReader();
      reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
      reader.onload = function (e) {
        // 转换完成，创建一个a标签用于下载
        var a = document.createElement("a");
        a.download = name;
        a.href = e.target.result;
        $("body").append(a); // 修复firefox中无法触发click
        a.click();
        $(a).remove();
      }
    },
  
    rowSelection(rows) {
      this.selRows = rows
    },
    // 点击排序
    onTableChange(sorter) {
      debugger
      const sortName = this.tcolumns.filter(it => it.prop == sorter.prop)
      if (sortName.length < 1) {
        return
      }
      if (sorter && sorter.order) {
        this.pagination.sortOrder =
          sorter.order == "ascending" ?
            "asc" :
            sorter.order == "descending" ?
              "desc" :
              "";
        this.pagination.sortName = sortName[0].sortName;
      } else {
        this.pagination.sortOrder = "";
        this.pagination.sortName = "";
      }
      this.getData();
    },
    // 获取列表数据
    getData() {
      this.tabelIsLoading = true;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          model: this.searchIpt,
          colour: "",
          warehouseId: "",
          invStatusId: "",
          isLock: "",
          dealerMaterialGroupFlag: "",
          materialGroupCode: "",
          materialGroupName: "",
          gicWarehouseType: "",
          invStatusType: "",
          invType: "",
          bigQtyMin: 0,
          orgId: "",
          ...this.terms
        },

        page: {
          pageNo: this.pagination.pageNo,
          pageSize: this.pagination.pageSize,
          sortName: this.pagination.sortName,
          sortOrder: this.pagination.sortOrder

        },
      };
      getProductList(data).then(res => {

        let data;
        if (typeof res.data == "string") {

          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        let tableData;
        tableData = data.data
        tableData.forEach(it => {
         it.attorneyLetter = ''
         if(it.dataSource == "O2O"){
          it.dataSourceText = "O2O订单"
         }else{
          it.dataSourceText = it.dataSource
         }
          if(it.ladingStatus == '2') {
            it.attorneyLetter = it.link
          }
          if( it.ladingStatus == 1 ) {
            it.attorneyLetter = '签署中'
          }
          if (it.customerName && it.phone) {
            it.contac = it.customerName + '-' + it.phone
          }
          if (it.customerName) {
            it.contac = it.customerName
          }
          if (it.phone) {
            it.contac = it.phone
          }
          // it.customerName = it.deliveryMode!='04'||it.documentType!='normal'?it.customerName:''
          it.addressDesc = it.deliveryMode!='04'||it.documentType!='normal'?it.addressDesc:''
          it.phone = it.deliveryMode!='04'||it.documentType!='normal'?it.phone:''
          if(it.isSpeclalShop == 'T') {
            it.isSpeclalShop ='是'
          }
          if(it.isSpeclalShop == 'F') {
            it.isSpeclalShop ='否'
          }
          if(it.isCrossCategory && it.isCrossCategory == 1) {
            it.isCrossCategory ='是'
          } else {
            it.isCrossCategory ='否'
          }
          it.uid = it.id
        })
        this.total = data.page.totalCount
        this.pagination.pageNo = data.page.pageNo
        this.pagination.pageSize = 10
        this.pagination.count = data.page.totalCount
        this.tableData = tableData
        this.tabelIsLoading = false;
        // this.tcolumns = JSON.parse(JSON.stringify(this.$refs.setTableRow.showList));
        // this.listChange(this.tcolumns)
      });

    },


    // 点击采购订单跳转
    linkClick(obj) {
      let routeUrl = this.$router.resolve({
        path: "/retailOrder/detail",
        query: {
          name:'销售订单进度汇总',
          id: obj.id
        }
      })
      window.open(routeUrl.href, "_blank");

    },
    //打印按钮点击
    printBtnClickFun(row){
      this.printTitle = '';//清空标题
      
      //数据接口请求完毕
      // isDataOk
      this.isDataOk = false;
      salesOrderPrint({ orderId: row.id }).then(res => {
        this.printTitleChangeFlag = true;
        let resData = JSON.parse(res.data);
        if (resData.code == 0) {
          this.printData.Master = resData.Master[0];
          this.printData.Details = resData.Details;
          this.printData.countTotal = 0;
          this.printData.amount = 0;
          for (const item of resData.Details) {
            this.printData.countTotal += (Number(item.backnowledgedQty) || 0);
            this.printData.amount += (Number(item.acknowledgedAmount)) || 0;
          }
          this.printTitle = this.printData.Master.printTitle;
          this.$nextTick(() => {
            // this.isDataOk = true;
          })
        }

      }).catch(err => {
        this.$message.warning('单据获取失败，请重试')
      });
    },
    // 销售订单跳转
    salrorderLink(data) {
      let routeUrl = this.$router.resolve({
        path: "/retailOrder/progress",
        query: {
          name:'销售订单进度汇总',
          id: data.id
        }
      });
      window.open(routeUrl.href, "_blank");
    },

    // 点击分页
    pageSearch(pager) {
      this.pagination.pageNo = pager.pageNo;
      this.pagination.pageSize = pager.pageSize;

      this.getData();
    },
    // 搜索
    searchFun(arg) {
      this.terms = arg[1];
      this.pagination.pageNo = 1
      this.pagination.pageSize = 10
      this.getData();
    },
    // 合计
    getSummariesFun(param) {

      // const showTotalColumns = []//控制显示的列
      const {
        columns,
        data
      } = param;
      let result = [];
      let obj = {};
      for (let i = 0; i < data.length; i++) {
        if (!obj[data[i].documentNum]) {
          result.push(data[i]);
          obj[data[i].documentNum] = true;
        }
      }

      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (!this.showTotalColumns.includes(column.label)) {
          return;
        }

        if (column.label == '下单数量' || column.label == '金额') {
          const values = result.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            // sums[index]= sums[index].toFixed(2) 

            sums[index] = sums[index].toFixed(2)
            sums[index] += " "; //单位

          } else {
            sums[index] = ""; //计算存在nan单位
          }
        } else {
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            // sums[index]= sums[index].toFixed(2) 
            sums[index] = sums[index].toFixed(2)
            sums[index] += " "; //单位
          } else {
            sums[index] = ""; //计算存在nan单位
          }
        }
      });

      return sums;
    },
    // listChange(e) {
    //   this.tcolumns = e
    //   let item = {
    //     label: "操作",
    //     slot: "btnClick",
    //     ellipsis: true,
    //     fixed: 'right',
    //     width: 200,
    //   }
    //   this.tcolumns.push(item)
    // }
    initTableColumns(tableColumns) {
      this.tcolumns = tableColumns.filter(j=>j.isShow === '1');
    },
    expandMore(){
      this.isExpand = !this.isExpand
    },
    // 导出发货条码
    async exportSalesOrder(){
      this.salesOrderLoading = true;
      let paramList = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          model: this.searchIpt,
          colour: "",
          warehouseId: "",
          invStatusId: "",
          isLock: "",
          dealerMaterialGroupFlag: "",
          materialGroupCode: "",
          materialGroupName: "",
          gicWarehouseType: "",
          invStatusType: "",
          invType: "",
          bigQtyMin: 0,
          orgId: "",
          ...this.terms
        },

        page: {
          pageNo: 1,
          pageSize: 300000,
          sortName: this.pagination.sortName,
          sortOrder: this.pagination.sortOrder

        },
      }
      console.log('导出参数',paramList)
      const hasOutDateField= (arr) => {
        return arr.some(obj => obj.field == 'outDate' );
      }
      if(!hasOutDateField(paramList.terms.paramList)){
        this.$message.warning('请选择出库日期')
        this.salesOrderLoading = false;
        return
      }
      try {
        const res = await exportSalesOrder(paramList);
        const result = JSON.parse(res.data);
        console.log('data结果',result)
        if (result.code === '0') {
          this.$message.success("下载成功，请到下载中心查看!")
        } else {
          this.$message.error("下载失败，请稍后再试！")
        }
      } catch (error) {
        this.$message.error(`导出错误！`);
      } finally {
        this.salesOrderLoading = false;
      }
    },
  }
}