// import http from "@/utils/request";
import service from "@/utils/request";
import http from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
// export function getProductList(data) {
//     return http({
//         url: 'getPurchaseOrderDetailList',
//         data
//     })
// }

export function getProductList(data) {
    return http({
        method: "post",
        url: "/order/salesOrderProgress.nd",
        data
    });
}

export function inStock(data) {
    return dmshttp({

        url: 'purchaseOrderBatchIn',
        data
    })
}
export function inStockSignle(data) {
    return dmshttp({
        url: 'purchaseOrderIn',
        data
    })
}

// 查仓库
export function findBaseData(data) {
    return dmshttp({
        url: 'findBaseData',
        data
    })
}
// 查仓库
export function getWarehouseList(data) {
    return dmshttp({
        url: 'getWarehouseList',
        data
    })
}


// 列表详情
export function findDetail(data) {
    return dmshttp({
        url: 'findPurchaseOrderDetail',
        data
    })
}

// 发货条码导出

export function exportSalesOrder(params) {
    return http({
        method: "post",
        url: "/order/machineCodeExport.nd",
        params,
        // responseType: 'blob'
    });
}

// 海信进度导出

export function exportOrder(params) {
    return http({
        method: "post",
        url: "/order/salesOrderProgressExport.nd",
        params,
        responseType: 'blob'
    });
}


export function getImg(data) {
    return dmshttp({
        url: 'toOmsView',
        data
    })
}
//渠道打印
export function salesOrderPrint(id) {
    return service({
      method: "get",
      url: "/order/orderLine.htm",
      params: {
        id: id
      }
    });
}
// 海信单打印
export function findSalesOrderPrint(data) {
    return service({
      method: "get",
      url: "/order/findSalesOrderPrint.nd",
      params:data
    });
}

// 自提委托书调用网关接口
export function sumbitAttorney(data) {
    return http({
        url: 'cts/ctsApi.nd',
        method: 'post',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    })
}
// cis发起自提委托书
export function sendLadingContract(data) {
    return http({
        method: "post",
        url: "/orderLadingContract/sendLadingContract.nd",
        data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}
// dms 发起自提委托书
export function dmsSendLadingContract(data) {
    return dmshttp({
        url: 'createLadingContract',
        data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    })
}
// 是否签署
export function backCheckproxysign(data) {
    return http({
        method: "post",
        url: "/orderLadingContract/backCheckproxysign.nd",
        data,
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
    });
}
// 查看下载自提委托书
// export function downContractById(data) {
//     return http({
//         method: "get",
//         url: "/orderLadingContract/downContractById.nd",
//         params:data
//     });
// }